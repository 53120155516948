import React from 'react'
import get from 'lodash.get'
import { parseISO, format } from 'date-fns'
import { getEventUrl } from 'helpers/eventUrl'
import _ from 'lodash'
import { injectIntl, navigate } from 'gatsby-plugin-react-intl'

import {
    Spacing,
    HeroSection,
    Container,
    EventCalendar,
} from '../kenra-storybook/index'

function onSelectEvent(link) {
    navigate(link)
}

const EventsList = ({ intl, events }) => {
    const unsortedEvents = []
    let today = new Date()
    const cutOff = today.setDate(today.getDate() - 30)

    events.forEach(event => {
        unsortedEvents.push({
            img: get(event, 'image.localFile.publicURL'),
            title: event.name,
            dayWeek: format(parseISO(event.date), 'E'),
            date: format(parseISO(event.date), 'MMMM d, Y'),
            actualDate: new Date(event.date),
            time: format(parseISO(event.date), 'h:mma'),
            endDate: new Date(event.endTime),
            link: getEventUrl(event),
            shortDescription: get(event, 'shortDescription.shortDescription'),
        })
    })

    let sortedEvents = []
    sortedEvents = _.orderBy(unsortedEvents, ['actualDate'])
    sortedEvents = sortedEvents.filter(event => {
        if (event.actualDate - cutOff > 0) {
            return true
        } else {
            return false
        }
    })

    return (
        <>
            <Spacing removeSpaceTop removeSpaceBottom>
                <HeroSection
                    title={intl.formatMessage({ id: 'events-title' })}
                    bgImg={`/images/events-list.jpg`}
                    invert
                />
            </Spacing>

            <Spacing>
                <Container>
                    <EventCalendar
                        items={sortedEvents}
                        onSelect={onSelectEvent}
                    />
                </Container>
            </Spacing>
        </>
    )
}

export default injectIntl(EventsList)
