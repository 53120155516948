import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import EventsList from 'components/EventList';
import SEO from 'components/seo';
import get from 'lodash.get';

export default function EventListPage(props) {
    let {
        data: { allContentfulEvent, contentfulPage },
    } = props;

    let storedRegion = '';
    if (typeof window !== 'undefined') {
        storedRegion = localStorage.getItem('preferred-region');
    }

    let filteredEvents = allContentfulEvent.nodes.filter(event => {
        if (
            typeof event.region === 'undefined' ||
            event.region === null ||
            event.region.length === 0 ||
            event.region.includes(storedRegion)
        ) {
            return true;
        } else {
            return false;
        }
    });

    return (
        <Layout>
            <SEO
                description={get(
                    contentfulPage,
                    'seoListing.description.description'
                )}
                title={get(contentfulPage, 'seoListing.seoTitle')}
                slug={'event-list'}
            />
            <EventsList events={filteredEvents} />
        </Layout>
    );
}

export const query = graphql`
    query($locale: String) {
        allContentfulEvent(filter: { node_locale: { eq: $locale } }) {
            nodes {
                name
                url
                image {
                    localFile {
                        publicURL
                    }
                }
                date
                endTime
                shortDescription {
                    shortDescription
                }
                node_locale
                region
            }
        }
        contentfulPage(
            title: { eq: "Event List" }
            node_locale: { eq: $locale }
        ) {
            ...ContentfulPageFields
        }
    }
`;
