var getEventUrl = function(event) {
    const { name, date, url } = event;
    if (url && url.length > 0) {
        return `/event/${url}`;
    } else {
        return `/event/${encodeURI(
            (name + '-' + date.replace(':', '-'))
                .toLowerCase()
                .replace(/\s/g, '-')
        )
            .replace('&', 'and')
            .replace(':', '-')
            .replace('-%7C', '')}`;
    }
};

module.exports.getEventUrl = getEventUrl;
